import { createContext } from "react";

export const AppContext = createContext<
  | {
      theme: "light" | "dark";
      setTheme:
        | React.Dispatch<React.SetStateAction<"light" | "dark">>;
        sidebarOpen: boolean;
        setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | any
>({});
