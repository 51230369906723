import { useContext } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import { AppContext } from "../../utils/state";
import DarkHeroImg from "../../assets/imgs/hero-image.png";
import LightHeroImg from "../../assets/imgs/light-hero-img.png";
import DarkModeBackGround from "../../assets/imgs/dark-hero-background-image.png";
import LandingCarouselBankCards from "../../assets/imgs/landing-carousel-cards.png";
import LandingFeaturesImg from "../../assets/imgs/landing-features-img.png";

const LandingPageWrapper = styled.div<{ theme: "light" | "dark" }>`
  .hero-image {
    width: 25vw;
    margin-left: 5vw;
  }

  .hero-title {
    font-size: 4vw;
    font-weight: bold;
  }

  .hero-summary {
    font-size: 2.3vw;
  }

  .hero-texts {
    color: ${(props) => (props.theme === "light" ? "#100768" : "white")};
  }

  .nav-btn {
    border-radius: 50px;
    text-transform: none;
    background-image: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(#4652FF, #170890)"
        : "linear-gradient(#784312, #DE7C21)"};
  }

  .hero-background {
    background-image: url(${(props) =>
      props.theme === "light" ? "unset" : DarkModeBackGround});
    background-repeat: no-repeat;
    display: inline-block;
    background-size: cover;
    background-position: center;
    height: 70vh;
  }

  a.google-play-btn {
    background-image: url(https://static.whatsapp.net/rsrc.php/v3/yD/r/iURpFV3szzs.png);
    background-size: auto;
    background-repeat: no-repeat;
    display: inline-block;
    height: 60px;
    width: 175px;
    margin-top: 5px;
  }
  a.apple-store-btn img {
    height: 50px;
  }

  .landing-carousel .carousel-root .carousel {
    display: none;
  }

  .landing-carousel .carousel-img img {
    width: 40vw;
    margin-top: -70px;
    margin-left: -90px;
  }

  .landing-carousel .carousel-root .carousel:first-child {
    display: block;
  }

  .landing-carousel {
    background-color: #042360;
    color: white;
    padding-left: 5.8vw;
    text-align: left;
  }

  .landing-carousel .carousel-root {
    padding: 10vh 0;
  }

  .carousel-text-title {
    text-align: left;
  }

  .carousel-text-summary {
    text-align: left;
    font-size: 2.5vw;
  }

  .landing-carousel .carousel .control-dots .dot {
    width: 2vw;
    height: 2vw;
    opacity: 1;
  }

  .landing-carousel .carousel .control-dots {
    text-align: left;
  }

  .landing-carousel .carousel .control-dots .selected {
    background-color: #fc8908;
  }

  .landing-carousel .carousel .carousel-status {
    display: none;
  }

  .landing-features {
    padding-left: 5.8vw;
  }

  .landing-features .title {
    text-decoration: underline;
    color: ${(props) => (props.theme === "light" ? "#100768" : "white")};
    font-size: 1.5rem;
    text-align: center;
  }

  .landing-features .features-subtitle {
    font-weight: bold;
    font-size: 1.5rem;
    color: ${(props) => (props.theme === "light" ? "#100768" : "#FC8908")};
  }

  .landing-features .features-summary {
    font-size: 16px;
    font-weight: 450;
    color: ${(props) => (props.theme === "light" ? "black" : "white")};
  }

  .landing-features .second-feature {
    margin-top: 10vh;
  }

  .landing-features-img {
    margin-top: 38vh;
  }

  .landing-features-img img {
    width: 25vw;
  }

  .landing-features ul.trusted-stars li {
    float: left;
  }
  .landing-features ul.trusted-stars li i {
    font-size: 12px;
    margin: 0 5px;
    color: ${(props) => (props.theme === "light" ? "#4652FF" : "#ffc48e")};
  }

  .white-paper-link {
    font-weight: bold;
    color: ${(props) => (props.theme === "light" ? "black" : "#FC8908")};
  }

  .white-paper-link-text {
    margin-left: 5px;
    color: ${(props) => (props.theme === "light" ? "#FC8908" : "white")};
  }

  .landing-get-started .nav-btn {
    width: 15vw;
    margin-top: -30px;
  }

  .landing-get-started {
    height: 20vh;
  }

  @media only screen and (min-width: 425px) {
    .hero-background {
      margin-top: unset !important;
      height: unset !important;
    }
  }

  @media only screen and (max-width: 62em),
    only screen and (max-width: 650px) {
    .hero-background {
      margin-top: 70px;
    }
    .hero-image {
      width: 80vw;
      margin-left: 5vw;
      opacity: .5;
    }

    .hero-title {
      font-size: 12vw;
      font-weight: bold;
    }

    .hero-summary {
      font-size: 6.5vw;
    }

    .hero-btns {
      text-align: center;
    }

    .hero-btns > a {
      margin-bottom: 15px;
    }

    .carousel-texts {
      margin-top: -50vh;
      background-color: #042360a3;
    }

    .carousel-text-summary {
      font-size: 6vw;
    }

    .landing-carousel .carousel {
      height: 80vh;
    }

    .slider {
      height: unset;
    }

    .landing-carousel .carousel .control-dots {
      text-align: left;
    }

    .landing-carousel .carousel .control-dots .dot {
      width: 6vw;
      height: 6vw;
    }

    .landing-carousel .carousel-img img {
      width: 135%;
    }

    .hero-texts {
      margin-top: ${(props) => (props.theme === "light" ? "-70vh" : "-47vh")};
      background-color: ${(props) =>
        props.theme === "light" ? "#ffffff69" : "#04236094"};
    }

    .landing-features .title {
      text-align: unset;
    }

    .landing-features .col {
      padding: unset;
    }

    .landing-features-img {
      margin-top: unset;
    }

    .landing-features-img img {
      width: 100vw;
      margin-bottom: -32vh;
    }

    .landing-features-second-list {
      background-color: ${(props) =>
        props.theme === "light" ? "#ffffff69" : "#04236094"};
    }

    .landing-get-started .nav-btn {
      width: 50vw;
    }

    

      @media only screen and (min-width: 481px) and (min-width: 601px) and (max-width: 1024px)  {
    .hero-background {
      margin-top: 70px;
    }

    .hero-image-col {
        margin-left: 10% !important;
        margin-top: -10% !important;
    }

    .hero-image-col .hero-image {
      margin-top: 10vh;
      opacity: .5;
    }

    .hero-texts-col {
    width: 90% !important;
    text-align: center;
    padding-left: 5%;
    }

    .hero-title {
      font-size: 9vw;
      font-weight: bold;
    }

    .hero-summary {
      font-size: 3.5vw;
    }

    .hero-btns {
      text-align: center;
    }

    .hero-btns > a {
      margin-bottom: 15px;
    }

    .carousel-texts {
      margin-top: -50vh;
      background-color: #042360a3;
    }
    
    .carousel-text-summary {
      font-size: 3.5vw;
      text-align: right;
    }

    .landing-carousel .carousel {
      height: unset;
    }

    .slider {
      height: unset;
    }

    .landing-carousel .carousel .control-dots {
      text-align: left;
    }

    .landing-carousel .carousel .control-dots .dot {
      width: 2vw;
      height: 2vw;
    }

  .landing-carousel .carousel-img img {
      width: 200%;
  }

    .hero-texts {
      margin-top: ${(props) => (props.theme === "light" ? "-75vh" : "-47vh")};
      background-color: ${(props) =>
        props.theme === "light" ? "#ffffff69" : "#04236094"};
    }

    .landing-features .title {
      text-align: center;
    }

    .landing-features .col {
      padding: unset;
    }

    .landing-features-img {
      margin-top: unset;
    }

    .landing-features-img img {
      width: 50vw;
      margin-bottom: -65vh;
      margin-left: -10vw;
    opacity: .5;
    }

    .landing-features-second-list {
      background-color: ${(props) =>
        props.theme === "light" ? "transparent" : "#04236094"};
    }

    .landing-get-started .nav-btn {
      width: 50vw;
    }
  }
`;

const LandingPage = () => {
  const { theme } = useContext(AppContext);

  return (
    <LandingPageWrapper theme={theme}>
      <div className="container-fluid">
        <div className="row hero-background">
          <div className="col m6 s12 l6 hero-image-col">
            {theme === "light" ? (
              <img
                src={LightHeroImg}
                alt="hero-image"
                className="responsive-img hero-image"
              />
            ) : (
              <img
                src={DarkHeroImg}
                alt="hero-image"
                className="responsive-img hero-image"
              />
            )}
          </div>
          <div className="col m6 s12 l6 hero-texts-col">
            <div className="hero-texts">
              <h3 className="hero-title">
                Our Product Fits Into Every Business Model
              </h3>
              <p className="hero-summary">
                Join over 200 million professionals around the world to enjoy
                quick and seamless payment system.
              </p>
              <div className="hero-btns">
                <a
                  href="#!"
                  className="apple-store-btn btn transparent z-depth-0"
                >
                  <img
                    src="https://static.whatsapp.net/rsrc.php/yT/r/DRlZi0ybQQP.svg"
                    alt="apple store link"
                  />
                </a>
                <a
                  href="#!"
                  className="google-play-btn btn transparent z-depth-0"
                >
                  <span className="hide">download from google play</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid landing-carousel">
        <Carousel infiniteLoop>
          <div className="row">
            <div className="col s12 m4 l4 hide-on-large-only">
              <div className="carousel-img">
                <img
                  src={LandingCarouselBankCards}
                  alt="payledger bank cards"
                />
              </div>
            </div>
            <div className="col m7 l7 s12">
              <div className="carousel-texts">
                <h3 className="hero-title carousel-text-title">
                  Global Payment <br /> Solution for Professionals
                </h3>
                <p className="hero-summary carousel-text-summary">
                  Join over 200 million professionals around <br />
                  the world to enjoy quick and seamless payment system.
                </p>
              </div>
            </div>
            <div className="col m4 l4 s12 hide-on-med-and-down">
              <div className="carousel-img">
                <img
                  src={LandingCarouselBankCards}
                  alt="payledger bank cards"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col m4 l4 s12 hide-on-large-only">
              <div className="carousel-img">
                <img
                  src={LandingCarouselBankCards}
                  alt="payledger bank cards"
                />
              </div>
            </div>
            <div className="col m7 l7 s12">
              <div className="carousel-texts">
                <h3 className="hero-title carousel-text-title">
                  Global Payment <br /> Solution for Professionals
                </h3>
                <p className="hero-summary carousel-text-summary">
                  Join over 200 million professionals around <br />
                  the world to enjoy quick and seamless payment system.
                </p>
              </div>
            </div>
            <div className="col m4 l4 s12 hide-on-med-and-down">
              <div className="carousel-img">
                <img
                  src={LandingCarouselBankCards}
                  alt="payledger bank cards"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col m4 l4 s12 hide-on-large-only">
              <div className="carousel-img">
                <img
                  src={LandingCarouselBankCards}
                  alt="payledger bank cards"
                />
              </div>
            </div>
            <div className="col m7 l7 s12">
              <div className="carousel-texts">
                <h3 className="hero-title carousel-text-title">
                  Global Payment <br /> Solution for Professionals
                </h3>
                <p className="hero-summary carousel-text-summary">
                  Join over 200 million professionals around <br />
                  the world to enjoy quick and seamless payment system.
                </p>
              </div>
            </div>
            <div className="col m4 l7 s12 hide-on-med-and-down">
              <div className="carousel-img">
                <img
                  src={LandingCarouselBankCards}
                  alt="payledger bank cards"
                />
              </div>
            </div>
          </div>
        </Carousel>
      </div>

      <div className="container-fluid landing-features">
        <h5 className="title">See all features available to you</h5>

        <div className="row">
          <div className="col m4 l4 s12">
            <ul>
              <li>
                <h5 className="features-subtitle">
                  For content creator and <br />
                  freelancers
                </h5>
                <p className="features-summary">
                  Connect your freelance and <br />
                  creative account to receive <br />
                  payments in one click.
                </p>
              </li>
              <li className="second-feature">
                <h5 className="features-subtitle">
                  Trusted platform for crypto exchange
                </h5>
                <div>
                  <ul className="trusted-stars">
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                  </ul>
                </div>
                <br />
                <p className="features-summary">
                  Cryptocurrency is the future of <br />
                  finance. Participate in the <br />
                  future of finance on our <br />
                  exchange.
                </p>
                <h6 className="white-paper-link">
                  Read our
                  <a className="white-paper-link-text" href="#!">
                    white paper
                  </a>
                </h6>
              </li>
            </ul>
          </div>
          <div className="col m4 l4 s12">
            <div className="landing-features-img">
              <img src={LandingFeaturesImg} alt="workers using payledger" />
            </div>
          </div>
          <div className="col m4 landing-features-second-list">
            <ul>
              <li>
                <h5 className="features-subtitle">For personal use</h5>
                <p className="features-summary">
                  Pay fees, send and receive <br />
                  funds from anywhere in the most <br />
                  convenient way.
                </p>
              </li>
              <li className="second-feature">
                <h5 className="features-subtitle">For businesses</h5>
                <p className="features-summary">
                  Set up your account on payledger in preferred currencies and
                  effortlessly accept payments from around the world. Enjoy the
                  convenience of a quick and reliable payment solution.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container-fluid landing-get-started center">
        <a href="#!" className="btn nav-btn">
          Get Started
        </a>
      </div>
    </LandingPageWrapper>
  );
};

export default LandingPage;
