import { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../utils/state";

import LightThemeLogo from "../assets/imgs/light-theme-logo.png";
import DarkThemeLogo from "../assets/imgs/dark-theme-logo.png";

import ThemeSwitchRipple from "./ThemeSwitchRipple";

import { routes } from "../Routes";

const NavbarWrapper = styled.div<{
  theme: "light" | "dark";
  sidebaropen: string;
}>`
  .btn-mobile {
    margin-right: 5vw;
  }

  nav {
    background-color: ${(props) =>
      props.theme === "light" ? "#FFFFFF" : "#2B3FB8"};
  }

  nav.below-nav {
    max-width: 30%;
    border-bottom-right-radius: 100px;
    box-shadow: 6px 5px 4px 0px rgba(0, 0, 0, 0.14);
    margin-left: ${(props) => (props.sidebaropen === "true" ? "220px" : "0")};
  }

  nav.top-nav {
    box-shadow: 6px 5px 4px 0px rgba(0, 0, 0, 0.14);
    margin-left: ${(props) => (props.sidebaropen === "true" ? "220px" : "0")};
    transition: margin-left 0.5s;
  }

  .hide-on-sidebar-open {
    display: ${(props) => (props.sidebaropen === "true" ? "none" : "block")};
  }

  ul.med-nav-links li {
    margin: 0 5px;
  }

  a.links {
    color: ${(props) => (props.theme === "light" ? "#100768" : "white")};
  }

  .mobile-sidebar-icon {
    color: ${(props) => (props.theme === "light" ? "#100768" : "white")};
  }

  .med-nav-links {
    margin-right: 5vw;
  }

  nav img.brand-logo {
    max-width: ${(props) => (props.theme === "light" ? "22.5" : "20")}%;
    height: auto;
    margin-left: 5vw;
  }

  .nav-btn {
    border-radius: 50px;
    text-transform: none;
    background-image: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(#4652FF, #170890)"
        : "linear-gradient(#784312, #DE7C21)"};
  }

  .sidenav {
    height: 100%;
    width: ${(props) => (props.sidebaropen === "true" ? "250px" : "0")};
    margin-left: ${(props) => (props.sidebaropen === "true" ? "250px" : "0")};
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #2b3fb8;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidenav li a {
    text-decoration: none;
    color: white;
    display: block;
    transition: 0.3s;
  }

  .sidenav a:hover {
    color: #f1f1f1;
  }

  .sidenav .closebtn {
    color: white;
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav li a {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 992px) {
    nav img.brand-logo {
      max-width: 40.5%;
      margin-left: 5vw;
      left: unset;
      transform: unset;
      width: 45vw;
      margin-left: 2vw;
      margin-top: 1vh;
    }

    .theme-ripple {
      margin-right: 5vw;
    }

    nav.below-nav {
      max-width: 45%;
    }

    .med-login {
      margin-right: 15px;
      font-weight: 600;
    }

    @media only screen and (min-width: 481px) and (min-width: 601px) and (max-width: 1024px) {
      .mobile-sidebar-icon {
      }
      nav img.brand-logo {
        max-width: 40.5%;
        margin-left: 5vw;
        left: unset;
        transform: unset;
        width: 45vw;
        margin-left: 2vw;
        margin-top: 1vh;
      }
    }
  }
`;

const Navbar = () => {
  const { theme, sidebarOpen, setSidebarOpen } = useContext(AppContext);

  return (
    <NavbarWrapper theme={theme} sidebaropen={sidebarOpen.toString()}>
      <ul className="sidenav">
        <a
          href="#!"
          className="closebtn"
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
          }}
        >
          &times;
        </a>
        {
          // eslint-disable-next-line
          routes
            .filter((item) => item.position === "navbar")
            .map((route) => (
              <li key={`sidebar-${route.path}-${route.title}`}>
                <a href={route.path}>{route.title}</a>
              </li>
            ))
        }
        {routes
          .filter((item) => item.position === "navbar-btn")
          .map((route) => (
            <li key={`${route.path}-${route.title}`}>
              <a href={route.path} className="btn nav-btn">
                {route.title}
              </a>
            </li>
          ))}
      </ul>
      <nav className="top-nav">
        <div className="nav-wrapper">
          {theme === "light" ? (
            <img
              className="brand-logo"
              src={LightThemeLogo}
              alt="payledger-logo"
            />
          ) : (
            <img
              className="brand-logo"
              src={DarkThemeLogo}
              alt="payledger-logo"
            />
          )}

          <ul className="med-nav-links right hide-on-med-and-down">
            {
              // eslint-disable-next-line
              routes
                .filter((item) => item.position === "navbar")
                .map((route) => (
                  <li key={`${route.path}-${route.title}`}>
                    <a className="links" href={route.path}>
                      {route.title}
                    </a>
                  </li>
                ))
            }

            <li>
              <ThemeSwitchRipple />
            </li>
            {routes
              .filter((item) => item.position === "navbar-btn")
              .map((route) => (
                <li key={`${route.path}-${route.title}`}>
                  <a href={route.path} className="btn nav-btn">
                    {route.title}
                  </a>
                </li>
              ))}
          </ul>

          <a
            href="#!"
            className="right mobile-sidebar-icon btn-mobile hide-on-large-only"
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
          >
            <i className="fa fa-bars"></i>
          </a>

          <a href="#!" className="right theme-ripple hide-on-large-only">
            <ThemeSwitchRipple />
          </a>
          <a className="links med-login right hide-on-large-only" href="/login">
            Login
          </a>
        </div>
      </nav>
      <nav className="below-nav"></nav>
    </NavbarWrapper>
  );
};

export default Navbar;
