import { Switch, Route } from "react-router-dom";
import LandingPage from "./LandingPage";
/**
 * these routes will be implemented into the navbar react helment implementation
 * how do I decide which route goes where
 *  -> a label
 *  -> what kind -> 
 */

export const routes = [
  {
    title: "Home",
    path: "/",
    element: LandingPage,
    position: ""
  },
  {
    title: "Contact Us",
    path: "/contact-us",
    element: LandingPage,
    position: "navbar"
  },
  {
    title: "About Us",
    path: "/about-us",
    element: LandingPage,
    position: "navbar"
  },
  {
    title: "Pricing",
    path: "/pricing",
    element: LandingPage,
    position: "navbar"
  },
  {
    title: "Login",
    path: "/login",
    element: LandingPage,
    position: "navbar"
  },
  {
    title: "Create an account",
    path: "/create-account",
    element: LandingPage,
    position: "navbar-btn"
  },
];

const RouteWrapper = ({Component}: {Component: any}) =>  <Component />;

const Routes = () => {
  return (
    <div>
      <Switch>
        {routes.map((route, index) => (
          <Route key={`${route.path} - ${index}`} path={route.path}>
            <RouteWrapper Component={route.element}/>
          </Route>
        ))}
      </Switch>
    </div>
  );
};

export default Routes;

